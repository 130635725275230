import React, { useEffect, useRef, useState } from "react";
import "./ProceduresDone.css";

function ProceduresDone() {
  const procedures = [
    "Injection Administration",
    "Dressing / Wound Care",
    "Sprain/Strain/Stitches",
    "Ear Piercing",
    "ECG",
    "Nebulization",
    "Ear Lobe Repair",
    "Abscess Incision and Drainage",
    "Excision",
    "Ear and Eye Care Procedures",
  ];

  const [isAnimated, setIsAnimated] = useState(false);
  const ref = useRef();

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const top = rect.top;
      const windowHeight = window.innerHeight;

      if (top < windowHeight * 0.75) {
        setIsAnimated(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`procedures-done-container ${isAnimated ? "animate" : ""}`} ref={ref}>
    
    

      <h1 className="titleProcedure">Procedures Done</h1>
      <ul className="procedures-list">
        {procedures.map((procedure, index) => (
          <li className="procedure-item" key={index}>
            {procedure}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProceduresDone;
