// SuccessStoriesPage.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './ssEU.css';
import FullScreenEvent from './FullScreenEvent';

const SuccessStoriesPage = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const storiesCollection = collection(db, 'success_stories');
      const storiesSnapshot = await getDocs(storiesCollection);
      const storiesData = storiesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
      setStories(storiesData);
      setLoading(false);

      const unsubscribe = onSnapshot(storiesCollection, (snapshot) => {
        const updatedStories = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
        setStories(updatedStories);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleReadMore = (story) => {
    setSelectedStory(story);
  };

  const handleCloseFullScreen = () => {
    setSelectedStory(null);
  };

  return (
    <div className="success-stories-page">
      <h1>Success Stories</h1>
      <div className="story-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {stories.map((story) => (
              <li key={story.id} className={story.expanded ? 'expanded' : ''}>
                <h3 className='storyTitle'>{story.title}</h3>
                <p className='StoryDate'>Date: {story.date}</p>
                <div className="story-image">
                  <img src={story.image} alt={story.title} onClick={() => handleReadMore(story)} />
                </div>
                <p className="story-description">
                  {story.description.length > 100 && !story.expanded
                    ? story.description.substring(0, 100) + '...'
                    : story.description}
                </p>
                {story.description.length > 100 && (
                  <button className='btttn' onClick={() => handleReadMore(story)}>
                    {story.expanded ? 'Read Less' : 'Read More'}
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {selectedStory && (
        <FullScreenEvent event={selectedStory} onClose={handleCloseFullScreen} />
      )}
    </div>
  );
};

export default SuccessStoriesPage;
