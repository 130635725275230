// AdminDoctorsPage.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../firebaseconfig';
import './DoctorsPageDyAdmin.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminDoctorsPage = () => {
  const [doctors, setDoctors] = useState([]);
  const [newDoctor, setNewDoctor] = useState({ name: '', specialty: '', image: '' });
  const [editDoctor, setEditDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newDoctorFile, setNewDoctorFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const doctorsCollection = collection(db, 'doctorsdynamic'); // Updated collection name
      const doctorsSnapshot = await getDocs(doctorsCollection);
      const doctorsData = doctorsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setDoctors(doctorsData);
    };

    fetchData();
  }, []);

  const uploadImageToStorage = async (imageFile) => {
    const imageRef = ref(storage, 'doctor_images/' + imageFile.name);
    await uploadBytes(imageRef, imageFile);
    return getDownloadURL(imageRef);
  };

  const handleAddDoctor = async () => {
    if (newDoctorFile && newDoctorFile.size > 1000048576) {
      alert('Image size is too large. Please select a smaller image.');
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await uploadImageToStorage(newDoctorFile);
      const doctorWithImage = { ...newDoctor, image: imageUrl };
      const newDoctorRef = await addDoc(collection(db, 'doctorsdynamic'), doctorWithImage); // Updated collection name

      setDoctors([...doctors, { id: newDoctorRef.id, ...doctorWithImage }]);
      setNewDoctor({ name: '', specialty: '', image: '' });
      setNewDoctorFile(null);

      window.alert('Doctor added successfully!');
    } catch (error) {
      console.error('Error adding doctor:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDoctor = async () => {
    if (editDoctor) {
      setLoading(true);

      try {
        if (editDoctor.image instanceof File) {
          const imageUrl = await uploadImageToStorage(editDoctor.image);
          editDoctor.image = imageUrl;

          const doctorRef = doc(db, 'doctorsdynamic', editDoctor.id); // Updated collection name
          await updateDoc(doctorRef, { image: imageUrl });
        }

        const { image, ...updatedDoctorData } = editDoctor;

        const doctorRef = doc(db, 'doctorsdynamic', editDoctor.id); // Updated collection name
        await updateDoc(doctorRef, updatedDoctorData);

        const updatedDoctors = doctors.map((doctor) =>
          doctor.id === editDoctor.id ? { ...doctor, ...editDoctor } : doctor
        );
        setDoctors(updatedDoctors);
        setEditDoctor(null);

        window.alert('Doctor updated successfully!');
      } catch (error) {
        console.error('Error updating doctor:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteDoctor = async (id) => {
    try {
      const doctorRef = doc(db, 'doctorsdynamic', id); // Updated collection name
      await deleteDoc(doctorRef);
      const updatedDoctors = doctors.filter((doctor) => doctor.id !== id);
      setDoctors(updatedDoctors);

      window.alert('Doctor deleted successfully!');
    } catch (error) {
      console.error('Error deleting doctor:', error);
    }
  };

  return (
    <div className="admin-doctors-page">
      <h1>Admin Doctors Page</h1>
      <div className="doctor-form">
        <h2>Add Doctor</h2>
        <input
          type="text"
          placeholder="Doctor Name"
          value={newDoctor.name}
          onChange={(e) => setNewDoctor({ ...newDoctor, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Specialty"
          value={newDoctor.specialty}
          onChange={(e) => setNewDoctor({ ...newDoctor, specialty: e.target.value })}
        />
        <input
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            setNewDoctorFile(file);
          }}
        />
        <button onClick={handleAddDoctor}>
          {loading ? 'Adding Doctor...' : 'Add Doctor'}
        </button>
      </div>
      <div className="doctorsss-list">
        <h2>Manage Doctors</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {doctors.map((doctor) => (
              <li key={doctor.id}>
                {editDoctor && editDoctor.id === doctor.id ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Doctor Name"
                      value={editDoctor.name}
                      onChange={(e) => setEditDoctor({ ...editDoctor, name: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="Specialty"
                      value={editDoctor.specialty}
                      onChange={(e) => setEditDoctor({ ...editDoctor, specialty: e.target.value })}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEditDoctor({ ...editDoctor, image: file });
                      }}
                    />
                    <button onClick={handleUpdateDoctor}>
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                    <button onClick={() => setEditDoctor(null)}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <h3>{doctor.name}</h3>
                    <p>Specialty: {doctor.specialty}</p>
                    <img src={doctor.image} alt={doctor.name} />
                    <button onClick={() => setEditDoctor({ ...doctor })}>Update</button>
                    <button onClick={() => handleDeleteDoctor(doctor.id)}>Delete</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminDoctorsPage;
