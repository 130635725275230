import React from 'react';
import './AboutUs.css';

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h1 className='title2'>Welcome to Ghala Clinic and Pharmacy</h1>
        <p>Established in 1992</p>
      </div>
      <div className="about-us-content">
        <p>
          With a legacy of 28 years, The Ghala Clinic and Pharmacy has been a trusted healthcare provider in Oman.
        </p>
        <p>
          Our commitment to the health and safety of our patients is unwavering. We offer comprehensive healthcare services not only to our patients but to their entire families, ensuring their well-being.
        </p>
        <p>
          At The Ghala Clinic and Pharmacy, we understand the financial burden that healthcare can bring. Therefore, we strive to minimize out-of-pocket expenses for our patients.
        </p>
      </div>
    </div>
  );
};

export default AboutUsPage;
