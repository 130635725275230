import React from "react";
import EventsEUPage from "../RecentNews/Events";
import SuccessStories from "../RecentNews/ssEU";

const Home = () => {
  return (
    <>
    
      <EventsEUPage/>
      
      <SuccessStories/>
    
    </>
  );
};

export default Home;
