import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../firebaseconfig';
import './SSadmin.css'; // You may want to create this CSS file.
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminSuccessStoriesPage = () => {
  const [stories, setStories] = useState([]);
  const [newStory, setNewStory] = useState({ title: '', description: '', date: '', image: '' });
  const [editStory, setEditStory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newStoryFile, setNewStoryFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const storiesCollection = collection(db, 'success_stories');
      const storiesSnapshot = await getDocs(storiesCollection);
      const storiesData = storiesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setStories(storiesData);
    };

    fetchData();
  }, []);

  const uploadImageToStorage = async (imageFile) => {
    const imageRef = ref(storage, 'story_images/' + imageFile.name);
    await uploadBytes(imageRef, imageFile);
    return getDownloadURL(imageRef);
  };

  const handleAddStory = async () => {
    if (newStoryFile && newStoryFile.size > 1000048576) {
      alert('Image size is too large. Please select a smaller image.');
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await uploadImageToStorage(newStoryFile);
      const storyWithImage = { ...newStory, image: imageUrl };
      const newStoryRef = await addDoc(collection(db, 'success_stories'), storyWithImage);

      setStories([...stories, { id: newStoryRef.id, ...storyWithImage }]);
      setNewStory({ title: '', description: '', date: '', image: '' });
      setNewStoryFile(null);

      window.alert('Success story added successfully!');
    } catch (error) {
      console.error('Error adding success story:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStory = async () => {
    if (editStory) {
      setLoading(true);

      try {
        if (editStory.image instanceof File) {
          const imageUrl = await uploadImageToStorage(editStory.image);
          editStory.image = imageUrl;

          const storyRef = doc(db, 'success_stories', editStory.id);
          await updateDoc(storyRef, { image: imageUrl });
        }

        const { image, ...updatedStoryData } = editStory;

        const storyRef = doc(db, 'success_stories', editStory.id);
        await updateDoc(storyRef, updatedStoryData);

        const updatedStories = stories.map((story) =>
          story.id === editStory.id ? { ...story, ...editStory } : story
        );
        setStories(updatedStories);
        setEditStory(null);

        window.alert('Success story updated successfully!');
      } catch (error) {
        console.error('Error updating success story:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteStory = async (id) => {
    try {
      const storyRef = doc(db, 'success_stories', id);
      await deleteDoc(storyRef);
      const updatedStories = stories.filter((story) => story.id !== id);
      setStories(updatedStories);

      window.alert('Success story deleted successfully!');
    } catch (error) {
      console.error('Error deleting success story:', error);
    }
  };

  return (
    <div className="admin-page">
      <h1>Admin Success Stories Page</h1>
      <div className="story-form">
        <h2>Add Success Story</h2>
        <input
          type="text"
          placeholder="Story Title"
          value={newStory.title}
          onChange={(e) => setNewStory({ ...newStory, title: e.target.value })}
        />
        <textarea
          placeholder="Story Description"
          value={newStory.description}
          onChange={(e) => setNewStory({ ...newStory, description: e.target.value })}
        />
        <input
          type="date"
          placeholder="Story Date"
          value={newStory.date}
          onChange={(e) => setNewStory({ ...newStory, date: e.target.value })}
        />
        <input
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            setNewStoryFile(file);
          }}
        />
        <button onClick={handleAddStory}>
          {loading ? 'Adding Success Story...' : 'Add Success Story'}
        </button>
      </div>
      <div className="story-list">
        <h2>Manage Success Stories</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {stories.map((story) => (
              <li key={story.id}>
                {editStory && editStory.id === story.id ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Story Title"
                      value={editStory.title}
                      onChange={(e) => setEditStory({ ...editStory, title: e.target.value })}
                    />
                    <textarea
                      placeholder="Story Description"
                      value={editStory.description}
                      onChange={(e) => setEditStory({ ...editStory, description: e.target.value })}
                    />
                    <input
                      type="date"
                      placeholder="Story Date"
                      value={editStory.date}
                      onChange={(e) => setEditStory({ ...editStory, date: e.target.value })}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEditStory({ ...editStory, image: file });
                      }}
                    />
                    <button onClick={handleUpdateStory}>
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                    <button onClick={() => setEditStory(null)}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <h3>{story.title}</h3>
                    <p>{story.description}</p>
                    <p>Date: {story.date}</p>
                    <img src={story.image} alt={story.title} />
                    <button onClick={() => setEditStory({ ...story })}>Update</button>
                    <button onClick={() => handleDeleteStory(story.id)}>Delete</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminSuccessStoriesPage;
