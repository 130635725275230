import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import './MAPLogin.css';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        console.log('User is signed in:', user);
      } else {
        // User is signed out
        console.log('User is signed out');
      }
    });

    return () => {
      // Cleanup the observer when the component unmounts
      unsubscribe();
    };
  }, [auth]);

  const handleLogin = async () => {
    try {
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);
      onLogin();
    } catch (error) {
      alert('Invalid credentials. Please try again.');
      console.error('Error signing in:', error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      alert('Error sending password reset email. Please try again.');
      console.error('Error sending password reset email:', error.message);
    }
  };

  return (
    <div className="login-form">
      <h3 className="alert">For internal use only</h3>
      <h1 className="ALlogintitle">Admin Login</h1>
      <input
        type="text"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Log In</button>
      <div className="forgotPASS" onClick={handleForgotPassword}>Forgot Password</div>
    </div>
  );
}

export default Login;
