// EventsPage.js
import React, { useEffect, useState } from 'react';
import FullScreenEvent from './FullScreenEvent';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './Events.css';

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsData = eventsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
      setEvents(eventsData);
      setLoading(false);

      const unsubscribe = onSnapshot(eventsCollection, (snapshot) => {
        const updatedEvents = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
        setEvents(updatedEvents);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleReadMore = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseFullScreen = () => {
    setSelectedEvent(null);
  };

  return (
    <div className="events-page">
      <h1>Events</h1>
      <div className="event-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {events.map((event) => (
              <li key={event.id} className={event.expanded ? 'expanded' : ''}>
                <h3>{event.title}</h3>
                <p className='EventDate'>Date: {event.date}</p>
                <div className="eventEU-imageEU">
                  <img src={event.image} alt={event.title} />
                </div>
                <p className="event-description">
                  {event.description.length > 100 && !event.expanded
                    ? event.description.substring(0, 100) + '...'
                    : event.description}
                </p>
                {event.description.length > 100 && (
                  <button className='btttn' onClick={() => handleReadMore(event)}>
                    {event.expanded ? 'Read Less' : 'Read More'}
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      {selectedEvent && (
        <FullScreenEvent event={selectedEvent} onClose={handleCloseFullScreen} />
      )}
    </div>
  );
};

export default EventsPage;
