import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './AdminTimingPage.css';

const AdminPage = () => {
  const [doctorTimings, setDoctorTimings] = useState([]);
  const [pharmacyTimings, setPharmacyTimings] = useState([]);
  const [newDoctor, setNewDoctor] = useState({ name: '', from: '', to: '' });
  const [newPharmacy, setNewPharmacy] = useState({ name: '', from: '', to: '' });
  const [editDoctor, setEditDoctor] = useState(null);
  const [editPharmacy, setEditPharmacy] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const doctorTimingsCollection = collection(db, 'doctortimings');
      const pharmacyTimingsCollection = collection(db, 'pharmacytimings');

      const doctorTimingsSnapshot = await getDocs(doctorTimingsCollection);
      const pharmacyTimingsSnapshot = await getDocs(pharmacyTimingsCollection);

      setDoctorTimings(doctorTimingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      setPharmacyTimings(pharmacyTimingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const handleAddDoctor = async () => {
    try {
      const newDoctorRef = await addDoc(collection(db, 'doctortimings'), newDoctor);
      setDoctorTimings([...doctorTimings, { id: newDoctorRef.id, ...newDoctor }]);
      setNewDoctor({ name: '', from: '', to: '' });
    } catch (error) {
      console.error('Error adding doctor timing:', error);
    }
  };

  const handleUpdateDoctor = async () => {
    if (editDoctor) {
      try {
        const doctorRef = doc(db, 'doctortimings', editDoctor.id);
        await updateDoc(doctorRef, editDoctor);
        const updatedDoctorTimings = doctorTimings.map(doctor =>
          doctor.id === editDoctor.id ? { ...doctor, ...editDoctor } : doctor
        );
        setDoctorTimings(updatedDoctorTimings);
        setEditDoctor(null);
      } catch (error) {
        console.error('Error updating doctor timing:', error);
      }
    }
  };

  const handleDeleteDoctor = async (id) => {
    try {
      const doctorRef = doc(db, 'doctortimings', id);
      await deleteDoc(doctorRef);
      const updatedDoctorTimings = doctorTimings.filter(doctor => doctor.id !== id);
      setDoctorTimings(updatedDoctorTimings);
    } catch (error) {
      console.error('Error deleting doctor timing:', error);
    }
  };

  const handleAddPharmacy = async () => {
    try {
      const newPharmacyRef = await addDoc(collection(db, 'pharmacytimings'), newPharmacy);
      setPharmacyTimings([...pharmacyTimings, { id: newPharmacyRef.id, ...newPharmacy }]);
      setNewPharmacy({ name: '', from: '', to: '' });
    } catch (error) {
      console.error('Error adding pharmacy timing:', error);
    }
  };

  const handleUpdatePharmacy = async () => {
    if (editPharmacy) {
      try {
        const pharmacyRef = doc(db, 'pharmacytimings', editPharmacy.id);
        await updateDoc(pharmacyRef, editPharmacy);
        const updatedPharmacyTimings = pharmacyTimings.map(pharmacy =>
          pharmacy.id === editPharmacy.id ? { ...pharmacy, ...editPharmacy } : pharmacy
        );
        setPharmacyTimings(updatedPharmacyTimings);
        setEditPharmacy(null);
      } catch (error) {
        console.error('Error updating pharmacy timing:', error);
      }
    }
  };

  const handleDeletePharmacy = async (id) => {
    try {
      const pharmacyRef = doc(db, 'pharmacytimings', id);
      await deleteDoc(pharmacyRef);
      const updatedPharmacyTimings = pharmacyTimings.filter(pharmacy => pharmacy.id !== id);
      setPharmacyTimings(updatedPharmacyTimings);
    } catch (error) {
      console.error('Error deleting pharmacy timing:', error);
    }
  };

  return (
    <div className="admin-page">
      <h1>Admin Page</h1>
      <div className="section">
        <h2>Manage Doctor Timings</h2>
        <div className="form">
          <input
            type="text"
            placeholder="Doctor Name"
            value={newDoctor.name}
            onChange={(e) => setNewDoctor({ ...newDoctor, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="From Time"
            value={newDoctor.from}
            onChange={(e) => setNewDoctor({ ...newDoctor, from: e.target.value })}
          />
          <input
            type="text"
            placeholder="To Time"
            value={newDoctor.to}
            onChange={(e) => setNewDoctor({ ...newDoctor, to: e.target.value })}
          />
          <button onClick={handleAddDoctor}>Add Doctor Timing</button>
        </div>
        <ul>
          {doctorTimings.map((doctor) => (
            <li key={doctor.id}>
              {editDoctor && editDoctor.id === doctor.id ? (
                <div>
                  <input
                    type="text"
                    placeholder="Doctor Name"
                    value={editDoctor.name}
                    onChange={(e) => setEditDoctor({ ...editDoctor, name: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="From Time"
                    value={editDoctor.from}
                    onChange={(e) => setEditDoctor({ ...editDoctor, from: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="To Time"
                    value={editDoctor.to}
                    onChange={(e) => setEditDoctor({ ...editDoctor, to: e.target.value })}
                  />
                  <button onClick={handleUpdateDoctor}>Save</button>
                  <button onClick={() => setEditDoctor(null)}>Cancel</button>
                </div>
              ) : (
                <div>
                  {doctor.name} - {doctor.from} to {doctor.to}
                  <button onClick={() => setEditDoctor({ ...doctor })}>Update</button>
                  <button onClick={() => handleDeleteDoctor(doctor.id)}>Delete</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="section">
        <h2>Manage Pharmacy Timings</h2>
        <div className="form">
          <input
            type="text"
            placeholder="Pharmacy Name"
            value={newPharmacy.name}
            onChange={(e) => setNewPharmacy({ ...newPharmacy, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="From Time"
            value={newPharmacy.from}
            onChange={(e) => setNewPharmacy({ ...newPharmacy, from: e.target.value })}
          />
          <input
            type="text"
            placeholder="To Time"
            value={newPharmacy.to}
            onChange={(e) => setNewPharmacy({ ...newPharmacy, to: e.target.value })}
          />
          <button onClick={handleAddPharmacy}>Add Pharmacy Timing</button>
        </div>
        <ul>
          {pharmacyTimings.map((pharmacy) => (
            <li key={pharmacy.id}>
              {editPharmacy && editPharmacy.id === pharmacy.id ? (
                <div>
                  <input
                    type="text"
                    placeholder="Pharmacy Name"
                    value={editPharmacy.name}
                    onChange={(e) => setEditPharmacy({ ...editPharmacy, name: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="From Time"
                    value={editPharmacy.from}
                    onChange={(e) => setEditPharmacy({ ...editPharmacy, from: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="To Time"
                    value={editPharmacy.to}
                    onChange={(e) => setEditPharmacy({ ...editPharmacy, to: e.target.value })}
                  />
                  <button onClick={handleUpdatePharmacy}>Save</button>
                  <button onClick={() => setEditPharmacy(null)}>Cancel</button>
                </div>
              ) : (
                <div>
                  {pharmacy.name} - {pharmacy.from} to {pharmacy.to}
                  <button onClick={() => setEditPharmacy({ ...pharmacy })}>Update</button>
                  <button onClick={() => handleDeletePharmacy(pharmacy.id)}>Delete</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPage;
