import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import './MAP.css'; // Import the CSS file
import Login from './MAPLogin'; // Import the Login component

const ButtonPage = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const handleLogin = () => {
    setAuthenticated(true);
  };

  const handleLogout = () => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        setAuthenticated(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error.message);
      });
  };

  return (
    <div className="button-container-MAP">
      {authenticated ? (
        <div>
          <h1 className="page-title-MAP">Admin Page</h1>
          <div className="button-group-MAP">
            <a href="/View_Appointments" className="button-primary-MAP">
              View Appointments
            </a>
            <a href="/EventsAdminPage" className="button-secondary-MAP">
              Manage Events
            </a>
            <a href="/Success_Stores_AdminPage" className="button-tertiary-MAP">
              Success Stories
            </a>

            <a href="/OffersAdminPage" className="button-primary-MAP">
              Manage Offers
            </a>
            <a href="/DoctorsAdminPage" className="button-secondary-MAP">
              Manage Doctors
            </a>
            <a href="/DFAP" className="button-secondary-MAP">
              Manage the list of Doctors for Schedule Appointment Page
            </a>
          

            <button className='MAPlogoutbutton' onClick={handleLogout}>Logout</button>
          </div>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default ButtonPage;
