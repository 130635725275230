import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../firebaseconfig';
import './EventsAdmin.css';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminEventsPage = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ title: '', description: '', date: '', image: '' });
  const [editEvent, setEditEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newEventFile, setNewEventFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const eventsCollection = collection(db, 'events');
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsData = eventsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
    };

    fetchData();
  }, []);

  const uploadImageToStorage = async (imageFile) => {
    const imageRef = ref(storage, 'event_images/' + imageFile.name);
    await uploadBytes(imageRef, imageFile);
    return getDownloadURL(imageRef);
  };

  const handleAddEvent = async () => {
    if (newEventFile && newEventFile.size > 1000048576) {
      alert('Image size is too large. Please select a smaller image.');
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await uploadImageToStorage(newEventFile);
      const eventWithImage = { ...newEvent, image: imageUrl };
      const newEventRef = await addDoc(collection(db, 'events'), eventWithImage);

      setEvents([...events, { id: newEventRef.id, ...eventWithImage }]);
      setNewEvent({ title: '', description: '', date: '', image: '' });
      setNewEventFile(null);

      window.alert('Event added successfully!');
    } catch (error) {
      console.error('Error adding event:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEvent = async () => {
    if (editEvent) {
      setLoading(true);

      try {
        if (editEvent.image instanceof File) {
          const imageUrl = await uploadImageToStorage(editEvent.image);
          editEvent.image = imageUrl;

          const eventRef = doc(db, 'events', editEvent.id);
          await updateDoc(eventRef, { image: imageUrl });
        }

        const { image, ...updatedEventData } = editEvent;

        const eventRef = doc(db, 'events', editEvent.id);
        await updateDoc(eventRef, updatedEventData);

        const updatedEvents = events.map((event) =>
          event.id === editEvent.id ? { ...event, ...editEvent } : event
        );
        setEvents(updatedEvents);
        setEditEvent(null);

        window.alert('Event updated successfully!');
      } catch (error) {
        console.error('Error updating event:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteEvent = async (id) => {
    try {
      const eventRef = doc(db, 'events', id);
      await deleteDoc(eventRef);
      const updatedEvents = events.filter((event) => event.id !== id);
      setEvents(updatedEvents);

      window.alert('Event deleted successfully!');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  return (
    <div className="admin-page">
      <h1>Admin Events Page</h1>
      <div className="event-form">
        <h2>Add Event</h2>
        <input
          type="text"
          placeholder="Event Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <textarea
          placeholder="Event Description"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
        />
        <input
          type="date"
          placeholder="Event Date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
        />
        <input
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            setNewEventFile(file);
          }}
        />
        <button onClick={handleAddEvent}>
          {loading ? 'Adding Event...' : 'Add Event'}
        </button>
      </div>
      <div className="event-list">
        <h2>Manage Events</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {events.map((event) => (
              <li key={event.id}>
                {editEvent && editEvent.id === event.id ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Event Title"
                      value={editEvent.title}
                      onChange={(e) => setEditEvent({ ...editEvent, title: e.target.value })}
                    />
                    <textarea
                      placeholder="Event Description"
                      value={editEvent.description}
                      onChange={(e) => setEditEvent({ ...editEvent, description: e.target.value })}
                    />
                    <input
                      type="date"
                      placeholder="Event Date"
                      value={editEvent.date}
                      onChange={(e) => setEditEvent({ ...editEvent, date: e.target.value })}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEditEvent({ ...editEvent, image: file });
                      }}
                    />
                    <button onClick={handleUpdateEvent}>
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                    <button onClick={() => setEditEvent(null)}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <h3>{event.title}</h3>
                    <p>{event.description}</p>
                    <p>Date: {event.date}</p>
                    <img src={event.image} alt={event.title} />
                    <button onClick={() => setEditEvent({ ...event })}>Update</button>
                    <button onClick={() => handleDeleteEvent(event.id)}>Delete</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminEventsPage;
