import React from 'react';
import './FullScreenEvent.css';

const FullScreenEvent = ({ event, onClose }) => {
  return (
    <div className="full-screen-event">
      <div className="event-modal">
        <button className="close-button" onClick={onClose}>X</button>
        <h3 className="ET">{event.title}</h3>
        <p className='EventDate'>Date: {event.date}</p>
        <div className="eventEU-imageEU">
          <img src={event.image} alt={event.title} />
        </div>
        <div className="event-description">
          {event.description}
        </div>
      </div>
    </div>
  );
};

export default FullScreenEvent;
