import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  addDoc,
  collection,
  query,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import './DoctorDFAP.css'; // Updated CSS filename

import '../../firebaseconfig';

function AdminDoctorManagement() {
  const [doctorName, setDoctorName] = useState('');
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);

  const db = getFirestore();

  const fetchDoctorsFromFirestore = async () => {
    const doctorsCollection = collection(db, 'doctors');
    const doctorDocs = await getDocs(doctorsCollection);

    const doctorData = [];
    doctorDocs.forEach((doc) => {
      const data = doc.data();
      doctorData.push({
        id: doc.id,
        name: data.name,
      });
    });

    setDoctorsList(doctorData);
  };

  useEffect(() => {
    // Fetch the list of doctors when the component mounts
    fetchDoctorsFromFirestore();
  }, []);

  const addDoctor = async () => {
    if (doctorName) {
      await addDoc(collection(db, 'doctors'), {
        name: doctorName,
      });
      setDoctorName('');
      fetchDoctorsFromFirestore();
    } else {
      alert('Please fill in all required fields.');
    }
  };

  const deleteDoctor = async (doctorId) => {
    await deleteDoc(doc(db, 'doctors', doctorId));
    fetchDoctorsFromFirestore();
  };

  const editDoctor = (doctorId) => {
    const selectedDoctor = doctorsList.find((doctor) => doctor.id === doctorId);
    if (selectedDoctor) {
      setDoctorName(selectedDoctor.name);
      setSelectedDoctorId(doctorId);
    }
  };

  const updateDoctor = async () => {
    if (doctorName && selectedDoctorId) {
      await updateDoc(doc(db, 'doctors', selectedDoctorId), {
        name: doctorName,
      });
      setDoctorName('');
      setSelectedDoctorId(null);
      fetchDoctorsFromFirestore();
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <div className="admin-doctor-management"> {/* Updated classname */}
      <h1>Admin Page - Manage the list of Doctors for Schedule Appointment Page</h1>
      <div>
        <input
          type="text"
          placeholder="Doctor Name"
          value={doctorName}
          onChange={(e) => setDoctorName(e.target.value)}
        />
      </div>
      <div className="buttons"> {/* Updated classname */}
        {!selectedDoctorId ? (
          <button onClick={addDoctor}>Add Doctor</button>
        ) : (
          <button onClick={updateDoctor}>Update Doctor</button>
        )}
      </div>
      <div>
        <h2>Doctors List</h2>
        <ul>
          {doctorsList.map((doctor) => (
            <li key={doctor.id}>
              {doctor.name}
              <button onClick={() => deleteDoctor(doctor.id)}>Delete</button>
              <button onClick={() => editDoctor(doctor.id)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdminDoctorManagement;
