import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db, storage } from '../../firebaseconfig';
import './OffersAdmin.css'; // You may want to create this CSS file.
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AdminOffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [newOffer, setNewOffer] = useState({ title: '', description: '', image: '' });
  const [editOffer, setEditOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newOfferFile, setNewOfferFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const offersCollection = collection(db, 'offers');
      const offersSnapshot = await getDocs(offersCollection);
      const offersData = offersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setOffers(offersData);
    };

    fetchData();
  }, []);

  const uploadImageToStorage = async (imageFile) => {
    const imageRef = ref(storage, 'offer_images/' + imageFile.name);
    await uploadBytes(imageRef, imageFile);
    return getDownloadURL(imageRef);
  };

  const handleAddOffer = async () => {
    if (newOfferFile && newOfferFile.size > 1000048576) {
      alert('Image size is too large. Please select a smaller image.');
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await uploadImageToStorage(newOfferFile);
      const offerWithImage = { ...newOffer, image: imageUrl };
      const newOfferRef = await addDoc(collection(db, 'offers'), offerWithImage);

      setOffers([...offers, { id: newOfferRef.id, ...offerWithImage }]);
      setNewOffer({ title: '', description: '', image: '' });
      setNewOfferFile(null);

      window.alert('Offer added successfully!');
    } catch (error) {
      console.error('Error adding offer:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOffer = async () => {
    if (editOffer) {
      setLoading(true);

      try {
        if (editOffer.image instanceof File) {
          const imageUrl = await uploadImageToStorage(editOffer.image);
          editOffer.image = imageUrl;

          const offerRef = doc(db, 'offers', editOffer.id);
          await updateDoc(offerRef, { image: imageUrl });
        }

        const { image, ...updatedOfferData } = editOffer;

        const offerRef = doc(db, 'offers', editOffer.id);
        await updateDoc(offerRef, updatedOfferData);

        const updatedOffers = offers.map((offer) =>
          offer.id === editOffer.id ? { ...offer, ...editOffer } : offer
        );
        setOffers(updatedOffers);
        setEditOffer(null);

        window.alert('Offer updated successfully!');
      } catch (error) {
        console.error('Error updating offer:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteOffer = async (id) => {
    try {
      const offerRef = doc(db, 'offers', id);
      await deleteDoc(offerRef);
      const updatedOffers = offers.filter((offer) => offer.id !== id);
      setOffers(updatedOffers);

      window.alert('Offer deleted successfully!');
    } catch (error) {
      console.error('Error deleting offer:', error);
    }
  };

  return (
    <div className="admin-page">
      <h1>Admin Offers Page</h1>
      <div className="offer-form">
        <h2>Add Offer</h2>
        <input
          type="text"
          placeholder="Offer Title"
          value={newOffer.title}
          onChange={(e) => setNewOffer({ ...newOffer, title: e.target.value })}
        />
        <textarea
          placeholder="Offer Description"
          value={newOffer.description}
          onChange={(e) => setNewOffer({ ...newOffer, description: e.target.value })}
        />
        <input
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            setNewOfferFile(file);
          }}
        />
        <button onClick={handleAddOffer}>
          {loading ? 'Adding Offer...' : 'Add Offer'}
        </button>
      </div>
      <div className="offer-list">
        <h2>Manage Offers</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {offers.map((offer) => (
              <li key={offer.id}>
                {editOffer && editOffer.id === offer.id ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Offer Title"
                      value={editOffer.title}
                      onChange={(e) => setEditOffer({ ...editOffer, title: e.target.value })}
                    />
                    <textarea
                      placeholder="Offer Description"
                      value={editOffer.description}
                      onChange={(e) => setEditOffer({ ...editOffer, description: e.target.value })}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setEditOffer({ ...editOffer, image: file });
                      }}
                    />
                    <button onClick={handleUpdateOffer}>
                      {loading ? 'Saving...' : 'Save'}
                    </button>
                    <button onClick={() => setEditOffer(null)}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <h3>{offer.title}</h3>
                    <p>{offer.description}</p>
                    <img src={offer.image} alt={offer.title} />
                    <button onClick={() => setEditOffer({ ...offer })}>Update</button>
                    <button onClick={() => handleDeleteOffer(offer.id)}>Delete</button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminOffersPage;
