import React, { useEffect, useState } from 'react';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebaseconfig';
import './OffersEU.css'; // Use the appropriate CSS file for "Offers"

const OffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const offersCollection = collection(db, 'offers');
      const offersSnapshot = await getDocs(offersCollection);
      const offersData = offersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
      setOffers(offersData);
      setLoading(false);

      const unsubscribe = onSnapshot(offersCollection, (snapshot) => {
        const updatedOffers = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), expanded: false }));
        setOffers(updatedOffers);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleReadMore = (offer) => {
    const updatedOffers = offers.map((o) =>
      o.id === offer.id ? { ...o, expanded: !o.expanded } : o
    );
    setOffers(updatedOffers);
  };

  return (
    <div className="offers-page">
      <h1>Our Offers You</h1>
      <h3></h3>
      <div className="offer-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {offers.map((offer) => (
              <li key={offer.id} className={offer.expanded ? 'expanded' : ''}>
                <h3>{offer.title}</h3>
                <p className="OfferDate">Date: {offer.date}</p>
                <div className="offer-image">
                  <img src={offer.image} alt={offer.title} />
                </div>
                <p className="offer-description">
                  {offer.description.length > 100 && !offer.expanded
                    ? offer.description.substring(0, 100) + '...'
                    : offer.description}
                </p>
                {offer.description.length > 100 && (
                  <button className='btttn' onClick={() => handleReadMore(offer)}>
                    {offer.expanded ? 'Read Less' : 'Read More'}
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default OffersPage;
