import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './ViewAppointments.css';
import '../../firebaseconfig';

function AppointmentsView() {
  const [appointments, setAppointments] = useState([]);
  const db = getFirestore();

  const fetchAppointmentsFromFirestore = async () => {
    const appointmentsCollection = collection(db, 'appointments');
    const appointmentsQuery = getDocs(appointmentsCollection);
    const appointmentData = [];

    const querySnapshot = await appointmentsQuery;
    querySnapshot.forEach((doc) => {
      appointmentData.push(doc.data());
    });

    appointmentData.sort((a, b) => new Date(b.requestTime) - new Date(a.requestTime));

    setAppointments(appointmentData);
  };

  useEffect(() => {
    fetchAppointmentsFromFirestore();
  }, []);

  const formatTime12Hour = (time24Hour) => {
    const [hours, minutes] = time24Hour.split(':');
    let period = '';
    let formattedHours = parseInt(hours);
  
    if (formattedHours >= 12) {
      period = '';
      formattedHours = formattedHours % 12 || 12;
    }
  
    formattedHours = formattedHours.toString().padStart(2, '0');
  
    return `${formattedHours}:${minutes} ${period}`;
  };
  

  const formatRequestTime = (timestamp) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(timestamp).toLocaleDateString(undefined, options);
  };

  const getAppointmentsForCategory = (category) => {
    const now = new Date();
    let filteredAppointments = [];

    switch (category) {
      case "Today's Appointments":
        filteredAppointments = appointments.filter((appointment) => {
          const appointmentDate = new Date(appointment.appointmentDate);
          return (
            appointmentDate.toDateString() === now.toDateString() &&
            appointmentDate.getMonth() === now.getMonth() &&
            appointmentDate.getYear() === now.getYear()
          );
        });
        break;
      case 'Upcoming Appointments':
        filteredAppointments = appointments.filter((appointment) => {
          const appointmentDate = new Date(appointment.appointmentDate);
          return appointmentDate > now && !filteredAppointments.includes(appointment);
        });
        break;
      case 'Past Appointments':
        filteredAppointments = appointments.filter((appointment) => {
          const appointmentDate = new Date(appointment.appointmentDate);
          if (
            appointmentDate.toDateString() !== now.toDateString() ||
            (appointmentDate.getMonth() !== now.getMonth() ||
              appointmentDate.getYear() !== now.getYear())
          ) {
            return appointmentDate < now;
          }
          return false;
        });
        break;
      default:
        filteredAppointments = appointments;
        break;
    }

    return filteredAppointments;
  };

  return (
    <div className="AppointmentsView">
      <div>
        <h1 className="title2">Appointments List</h1>
        <div className="categories">
          <CategoryComponent title="Today's Appointments" appointments={getAppointmentsForCategory("Today's Appointments")} formatRequestTime={formatRequestTime} formatTime12Hour={formatTime12Hour} />
          <CategoryComponent title="Upcoming Appointments" appointments={getAppointmentsForCategory('Upcoming Appointments')} formatRequestTime={formatRequestTime} formatTime12Hour={formatTime12Hour} />
          <CategoryComponent title="Past Appointments" appointments={getAppointmentsForCategory('Past Appointments')} formatRequestTime={formatRequestTime} formatTime12Hour={formatTime12Hour} />
        </div>
        <CategoryComponent title="Total Appointments" appointments={appointments} formatRequestTime={formatRequestTime} formatTime12Hour={formatTime12Hour} />
      </div>
    </div>
  );
}

function CategoryComponent({ title, appointments, formatRequestTime, formatTime12Hour }) {
  return (
    <div>
      <h2 className="category-heading">{title} ({appointments.length})</h2>
      <AppointmentsList appointments={appointments} formatRequestTime={formatRequestTime} formatTime12Hour={formatTime12Hour} />
    </div>
  );
}

function AppointmentsList({ appointments, formatRequestTime, formatTime12Hour }) {
  return (
    <div className="AppointmentsList">
      <table className="appointments-table">
        <thead>
          <tr>
            <th className="table-header">Patient Name</th>
            <th className="table-header">Phone</th>
            <th className="table-header">Email</th>
            <th className="table-header">Doctor</th>
            <th className="table-header">Date</th>
            <th className="table-header">Time</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment, index) => (
            <tr key={index} className="table-row">
              <td className="table-data">{appointment.patientName}</td>
              <td className="table-data">{appointment.mobileNumber}</td>
              <td className="table-data">{appointment.email}</td>
              <td className="table-data">{appointment.doctor}</td>
              <td className="table-data">{appointment.appointmentDate}</td>
              <td className="table-data">{formatTime12Hour(appointment.appointmentTime)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AppointmentsView;
