import React from 'react';
import './ServicesPage.css';

const ServicesPage = () => {
  const services = [
    {
      title: 'Out-patient clinic',
      description: 'Comprehensive medical care at our clinic.'
    },
    {
      title: 'Urgent medical care',
      description: 'Immediate medical attention for urgent cases.'
    },
    {
      title: 'Laboratory services',
      description: 'State-of-the-art diagnostic laboratory for accurate testing.'
    },
    {
      title: 'Pharmacy',
      description: 'Convenient in-house pharmacy for prescriptions.'
    },
    {
      title: 'Geriatric care',
      description: 'Specialized care for senior citizens.'
    },
    {
      title: 'Medical care at home',
      description: 'Medical services provided in the comfort of your home.'
    },
    {
      title: 'Diabetes - Hypertension management',
      description: 'Tailored management programs for diabetes and hypertension.'
    },
    {
      title: 'Minor surgical procedures',
      description: 'Minimally invasive surgical procedures.'
    },
    {
      title: 'International medical coordination services',
      description: 'Assistance for international patients seeking medical care.'
    },
    {
      title: 'Preventive and family health care',
      description: 'Preventive healthcare and family-oriented services.'
    },
    {
      title: 'Preventive health check-up packages',
      description: 'Comprehensive health check-up packages.'
    }
  ];

  return (
    <div className="services-container">
      <h2 className="titleSERV">Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
